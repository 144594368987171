var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.RetentionModal)?_c('nopay',{attrs:{"visible":_vm.RetentionModal,"money":_vm.money},on:{"close":()=>{
    this.clearTime()
    this.RetentionModal = false
  },"onsubmit1":()=>{
    this.getCreateOrder(_vm.json)
  },"onsubmit2":()=>{
    this.finishPay(_vm.json.order_sn)
  }}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }