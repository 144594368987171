<template>
  <div class="container">
    <div class="floats_right">
      <p><a @click="()=>{
            contentHtml = textInfo.rule
            contentHtmlModal = true
          }">规则 ></a></p>
      <p><router-link to="/service">客服 ></router-link></p>
    </div>

    <div class="con_text">
      <div class="text_1">恭喜你获得</div>
      <div class="text_2"><span>{{money.price}}</span>充值<span>{{money.total_price}}</span>元话费券</div>
    </div>

    <div class="cover-image"><img :src="oss+'ic_price.png'"/></div>
    <div class="main">
      <div class="phone">
        <van-field v-model="json.phone" type="tel" :border="false" ref="phone" placeholder="请输入您的手机号码">
          <template #left-icon>
            <van-icon class-prefix="icon" name="mobile" size="20" color="#999"/>
          </template>
        </van-field>
      </div>
      <div class="paybtn" @click="onsubmit"><img :src="oss+'ic_charge.png'"/></div>
      <div class="tips">
        <van-checkbox v-model="isread" icon-size="16px" checked-color="#f6c14f">我已阅读并同意</van-checkbox>
        <a @click="()=>{
            contentHtml = textInfo.privacy_policy
            contentHtmlModal = true
          }">《用户隐私协议》</a>
        <a @click="()=>{
            contentHtml = textInfo.user_agreement
            contentHtmlModal = true
          }">《用户协议》</a>
      </div>

    </div>
    <div class="foot">
      <p>客服电话：4006178158</p>
      <p>{{textInfo.company_name}}</p>
    </div>

    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#ec4a26" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>

    <payment :data="json" :money="money" ref="payment" v-if="money.price" :showRetention="1"/>
  </div>
</template>
<script>
  import {isWeixin} from '@/utils/utils'
  import payment from './components/payment'
  import {GetPayConfig,GetUserPrivate} from './services/services'
  export default {
    name:'charge',
    components: {payment},
    data () {
      return {
        money:{},
        json:{
          id:0,
          pay_way:0,
          order_sn:'',
          phone:'',
          account:'',
          url:location.href
        },
        detail:{},
        contentHtmlModal:false,
        contentHtml:'',
        isread:true
      }
    },
    created(){
      const {phone} = this.$route.query
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }
      this.isWeixin = isWeixin()?1:0
      // this.isWeixin = 1
      if(this.isWeixin){
        this.json.pay_way = 14
      }
      this.type = this.json.type==undefined ? undefined:this.json.type
    },
    mounted(){
      this.findpayConfig()
      this.finduserprivate()
    },
    watch:{
      'json.phone':{
        handler:function(value){
          if(value.length == 11){
            if(!/^1[3456789]\d{9}$/.test(value)) {
              this.$toast('请输入正确的手机号码')
              this.$refs.phone.focus()
            }else{
              this.$refs.phone.blur()
            }
          }
        },
        deep:true
      }
    },
    methods:{
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            this.detail = this.detail = result.data.data
            let payWay = this.detail.payConfig[0].payWay
            let pay=this.detail.payConfig.find(item=>item.payWay == payWay)
            const {pwyAccount} = pay
            this.json.pay_way=payWay
            this.json.account=pwyAccount

            let money=this.detail.goodsArray.find(item=>item.status==1)
            this.money=money
            document.title = this.money.price+'充值200元话费券'
            this.json.id=money.id

            const {app_download_url,kefu_url} = this.detail
            let data = {app_download_url,kefu_url}
            sessionStorage.setItem('payConfig',JSON.stringify(data))
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      finduserprivate(){
        GetUserPrivate().then(result => {
          if(result.data.code==1){
            this.textInfo = result.data.data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      onsubmit(){
        if(!this.isread){
          this.$toast('请阅读隐私协议与用户协议')
          return false
        }
        if(this.json.phone==''){
          this.$toast('请输入充值手机号')
          this.$refs.phone.focus()
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          return false
        }
        let params = {
          ...this.$route.query,
          phone:btoa(this.json.phone)
        }
        this.$router.push({path:'/pay100',query:params})
        // this.$refs.payment.onsubmit(this.json)
      }
    }
  }

</script>
<style scoped lang="less">
  .container{background:#000000 url('@{static-dir}bg_charge.png') no-repeat center top;background-size:100% auto;position:relative;

    .floats_right{position:fixed;right:0px;top:20px;z-index:9;
      p{padding:6px 0px;
        a{background:#f9dc98;color:#aa733e;border-radius:20px 0px 0px 20px;padding:3px 8px 4px 18px;display:inline-block;font-size:12px;}
      }
    }
    .con_text{padding:172px 30px 0px 30px;height:76px;text-align:center;color:#f9da9e;line-height:40px;font-size:24px;
      .text_1{}
      .text_2{}
      span{font-family:microsoft yahei,arial,verdana;}
    }
    .cover-image{
      img{width:100%;}
    }
    .main{padding:0px 12%;margin-top:45px;
      .phone{
        .van-cell{border-radius:4px;border:none;}
      }
      .paybtn{margin-top:15px;
        img{width:100%;}
      }
      .tips{margin-top:20px;font-size:12px;display:flex;
        span{display:inline-block;}
        a{color:#f6c14f;}
        /deep/.van-checkbox__label{font-size:12px;color:#fff;}
      }
    }

    .foot{color:#fff;text-align:center;margin-top:20px;line-height:24px;padding:25px 0px;}
    .content{color:#999;padding:20px;font-size:12px;
      /deep/p{padding:4px 0px;}
    }
  }
</style>
