var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-popup',{staticClass:"RetentionPop",attrs:{"overlay-style":{backgroundColor:'rgba(0,0,0,.5)'},"close-on-click-overlay":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('van-icon',{staticClass:"close",attrs:{"name":"cross","size":"24","color":"#fff"},on:{"click":()=>{
    this.$emit('close')
  }}}),_c('div',{staticClass:"price"},[_vm._v("¥ "),_c('span',[_vm._v(_vm._s(_vm.money.price))])]),_c('div',{staticClass:"old_price"},[_vm._v("原价 "),_c('s',[_vm._v("¥ "+_vm._s(_vm.money.total_price))])]),_c('div',{staticClass:"text1"},[_vm._v("200元话费优惠券")]),_c('div',{staticClass:"text"},[_c('van-count-down',{attrs:{"millisecond":"","time":60000},scopedSlots:_vm._u([{key:"default",fn:function(timeData){return [_c('span',{staticClass:"block"},[_vm._v(_vm._s(timeData.seconds))]),_c('span',{staticClass:"colon"},[_vm._v(".")]),_c('span',{staticClass:"block"},[_vm._v(_vm._s(_vm.moment(timeData.milliseconds).format("S")))]),_c('span',{staticClass:"s"},[_vm._v("秒后收回资格，欲购从速")])]}}])})],1),_c('div',{staticClass:"btn"},[_c('van-button',{attrs:{"round":"","block":""},on:{"click":()=>{
      this.$emit('onsubmit1')
    }}},[_vm._v(_vm._s(_vm.money.price)+"元立即抢购")]),_c('span',{staticClass:"tips"},[_vm._v("不回本可退款")])],1),_c('div',{staticClass:"pay_texts",on:{"click":()=>{
      this.$emit('onsubmit2')
    }}},[_vm._v("我已付过款")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }