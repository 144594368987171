<template>
  <van-popup v-model="visible" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="RetentionPop">
    <van-icon name="cross" @click="()=>{
      this.$emit('close')
    }" class="close" size="24" color="#fff" />
    <div class="price">¥ <span>{{money.price}}</span></div>
    <div class="old_price">原价 <s>¥ {{money.total_price}}</s></div>
    <div class="text1">200元话费优惠券</div>
    <div class="text">
      <van-count-down millisecond :time="60000">
        <template #default="timeData" >
          <span class="block">{{ timeData.seconds }}</span>
          <span class="colon">.</span>
          <span class="block">{{moment(timeData.milliseconds).format("S")}}</span>
          <span class="s">秒后收回资格，欲购从速</span>
        </template>
      </van-count-down>
    </div>

    <div class="btn">
      <van-button round block @click="()=>{
        this.$emit('onsubmit1')
      }">{{money.price}}元立即抢购</van-button>
      <span class="tips">不回本可退款</span>
    </div>

    <div class="pay_texts" @click="()=>{
        this.$emit('onsubmit2')
      }">我已付过款</div>
  </van-popup>
</template>
<script>
  import moment from 'moment'
  export default {
    props: {
      money:Object,
      visible:Boolean,
    },
    components: {},
    data () {
      return {

      }
    },
    created(){


    },
    mounted(){

    },
    methods: {
      moment
    }
  }

</script>
<style scoped lang="less">
  .RetentionPop{width:96%;border-radius:6px;padding:90px 2% 0px 2%;text-align:center;background:url('@{static-dir}adv_bg20221220.png') no-repeat;background-size:100% auto;height:340px;
    .close{position:absolute;right:2%;top:0px;z-index:1;}
    .price{width:150px;padding-top:40px;margin:20px auto 0px;color:#c91e4a;font-size:24px;
      span{font-size:56px;}
    }
    .old_price{color:#999;}
    .text{margin-top:5px;
      .van-count-down{color:#ff0000;
        .s{font-size:14px;margin-left:8px;color:#888;}
      }
    }
    .text1{color:#875324;margin-top:10px;}
    .btn{position:relative;width:68%;margin:50px auto 0px;
      .van-button{-webkit-animation:paybtn_scalc 1s ease infinite;animation:paybtn_scalc 1s ease infinite;background:linear-gradient(to bottom,#ffa100,#ff2204);color:#fff;border:none;
        .van-button__text{font-size:20px;}
      }

      .tips{position:absolute;background:#ff1e05;color:#fff;right:0px;top:-22px;padding:5px 15px 6px 15px;border-radius:20px 20px 20px 0px;font-size:12px;}
    }
    .pay_texts{color:#000000;font-weight:700;margin-top:10px;}
    /deep/.van-popup__close-icon--top-right{top:0px;}
  }
  @keyframes paybtn_scalc{
    0% {
      -webkit-transform:scale(1);
      transform: scale(1);
    }
    25% {
      -webkit-transform:scale(1.05);
      transform: scale(1.05);
    }
    50% {
      -webkit-transform:scale(1.02);
      transform: scale(1.02);
    }
    75% {
      -webkit-transform:scale(1.05);
      transform: scale(1.05);
    }
    100% {
      -webkit-transform:scale(1);
      transform: scale(1);
    }
  }
</style>
