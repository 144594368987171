import {
  payconfig,userprivate,createOrder,getOrderStatus,refund
} from './api'
import {request, METHOD} from '@/utils/request'

export async function GetPayConfig(params) {
  return request(payconfig, METHOD.POST,params)
}
export async function GetUserPrivate(params) {
  return request(userprivate, METHOD.POST,params)
}

export async function CreateOrder(params) {
  return request(createOrder, METHOD.POST,params)
}
export async function GetOrderStatus(params) {
  return request(getOrderStatus, METHOD.POST,params)
}

export async function PostRefund(params) {
  return request(refund, METHOD.POST,params)
}
