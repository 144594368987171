<template>
  <nopay v-if="RetentionModal" :visible="RetentionModal" :money="money" @close="()=>{
      this.clearTime()
      this.RetentionModal = false
    }" @onsubmit1="()=>{
      this.getCreateOrder(json)
    }" @onsubmit2="()=>{
      this.finishPay(json.order_sn)
    }"/>
</template>
<script>
  import {isWeixin,getordernum} from '@/utils/utils'
  import {initCloud} from '@wxcloud/cloud-sdk'
  import nopay from './nopay'
  import {CreateOrder, GetOrderStatus} from "../services/services";
  export default {
    props: {
      money:Object,
      data:Object,
      showRetention:Number
    },
    components: {nopay},
    data () {
      return {
        isWeixin:0,
        json:{},
        timer:null,
        RetentionModal:false,//挽留
        count:0
      }
    },
    created(){
      this.json={
        ...this.json,
        ...this.data
      }
      this.isWeixin = isWeixin()?1:0
      // this.isWeixin = 1
      if(this.isWeixin){
        this.json.pay_way = 14
      }
    },
    mounted(){
      if(sessionStorage.getItem('order_info')){
        let order_info = JSON.parse(sessionStorage.getItem('order_info'))
        this.json={
          ...this.json,
          ...order_info
        }

        this.findOrderStatus(this.json.order_sn)
        return false
      }
      if(this.json.order_sn){
        this.findOrderStatus(this.json.order_sn)
      }
    },
    methods: {
      onsubmit(res){
        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })
        this.json={
          ...this.json,
          ...res
        }
        let order_sn = this.json.order_sn==''?getordernum(6):this.json.order_sn
        let json={
          ...this.json,
          order_sn:order_sn
        }
        this.getCreateOrder(json)
      },
      getCreateOrder(res){
        CreateOrder({...res,openid:(this.isWeixin?this.json.openid:undefined),pay_tag:((this.isWeixin || res.pay_way==14)?'wechat':'alipay')}).then(result => {
          if(result.data.code==1){
            sessionStorage.setItem('order_info',JSON.stringify(res))
            const {data} = result.data
            let pay_way = res.pay_way
            if(pay_way==14){
              location.href=data
              // this.payWeChat(data)
            }else if(pay_way==16){
              this.Alipay(data)
            }

          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      async payWeChat(orderId){
        let c1 = new this.initCloud().Cloud({
          // 必填，表示是未登录模式
          identityless: true,
          // 资源方 AppID
          resourceAppid: 'wxdada9bc45300a359',
          // 资源方环境 ID
          resourceEnv: 'cloud1-3gua9rkm875bd364',
        })

        await c1.init()
        const res =  await c1.callFunction({
          name: 'public',
          data: {
            action: 'getUrlScheme',
            path:'pages/index/index', // 想跳转的小程序路径
            orderId:orderId, // 预下单单号
          },
        }).then((res)=>{
          let result = res.result
          if(result.errCode==0){
            location.href = result.openlink
          }
        })
      },
      Alipay(res){
        const div = document.createElement("formdiv")
        div.innerHTML = res
        document.body.appendChild(div)
        document.forms["pay"].setAttribute("target", "_self")
        document.forms["pay"].submit()
        div.remove()
      },
      findOrderStatus(order_sn){
        GetOrderStatus({order_sn:order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            // this.paysuccess = code
            clearInterval(this.timer)
            this.$router.push({path:'/success'})
          }else{
            if(this.showRetention && this.money.price==29.9){
              if(!this.RetentionModal){
                this.RetentionModal = true
              }
            }else{
              this.$dialog.confirm({
                title: '确认支付情况',
                confirmButtonText:'已完成支付',
                cancelButtonText:'重新支付',
                closeOnClickOverlay:true
              }).then(() => {
                this.finishPay(order_sn)
              }).catch((res) => {
                if(res == 'overlay'){
                  this.clearTime()
                }else{
                  this.getCreateOrder(this.json)
                }
              })
            }

            if(this.timer == null){
              this.getTimerStatus(order_sn)
            }
          }
        })
      },
      getTimerStatus(order_sn){
        this.timer = setInterval(() => {
          if(this.count>=5){
            // this.create_status=1
            this.clearTime()
            return false
          }
          this.count=this.count+1
          this.findOrderStatus(order_sn)
        },3*1000)
      },
      finishPay(order_sn){//支付完成
        GetOrderStatus({order_sn:order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = code
            this.clearTime()
            this.$router.push({path:'/success'})
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
        // location.href = 'https://api.youzuanmy.vip/xd/paysuccess.html?orderNo='+this.json.order_sn
      },
      clearTime(){
        clearInterval(this.timer)
        sessionStorage.removeItem('order_info')
      },
      onSubmitPay(status){
        switch (status) {
          case 1:
            this.finishPay()
            break;
          case 0:
            this.getCreateOrder(this.json)
            break;
        }
      },
    }
  }

</script>
<style scoped lang="less">
</style>
