var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"floats_right"},[_c('p',[_c('a',{on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.rule
          _vm.contentHtmlModal = true
        }}},[_vm._v("规则 >")])]),_c('p',[_c('router-link',{attrs:{"to":"/service"}},[_vm._v("客服 >")])],1)]),_c('div',{staticClass:"con_text"},[_c('div',{staticClass:"text_1"},[_vm._v("恭喜你获得")]),_c('div',{staticClass:"text_2"},[_c('span',[_vm._v(_vm._s(_vm.money.price))]),_vm._v("充值"),_c('span',[_vm._v(_vm._s(_vm.money.total_price))]),_vm._v("元话费券")])]),_c('div',{staticClass:"cover-image"},[_c('img',{attrs:{"src":_vm.oss+'ic_price.png'}})]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"phone"},[_c('van-field',{ref:"phone",attrs:{"type":"tel","border":false,"placeholder":"请输入您的手机号码"},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('van-icon',{attrs:{"class-prefix":"icon","name":"mobile","size":"20","color":"#999"}})]},proxy:true}]),model:{value:(_vm.json.phone),callback:function ($$v) {_vm.$set(_vm.json, "phone", $$v)},expression:"json.phone"}})],1),_c('div',{staticClass:"paybtn",on:{"click":_vm.onsubmit}},[_c('img',{attrs:{"src":_vm.oss+'ic_charge.png'}})]),_c('div',{staticClass:"tips"},[_c('van-checkbox',{attrs:{"icon-size":"16px","checked-color":"#f6c14f"},model:{value:(_vm.isread),callback:function ($$v) {_vm.isread=$$v},expression:"isread"}},[_vm._v("我已阅读并同意")]),_c('a',{on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.privacy_policy
          _vm.contentHtmlModal = true
        }}},[_vm._v("《用户隐私协议》")]),_c('a',{on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.user_agreement
          _vm.contentHtmlModal = true
        }}},[_vm._v("《用户协议》")])],1)]),_c('div',{staticClass:"foot"},[_c('p',[_vm._v("客服电话：4006178158")]),_c('p',[_vm._v(_vm._s(_vm.textInfo.company_name))])]),(_vm.contentHtmlModal)?_c('van-popup',{staticClass:"contentPop",attrs:{"overlay-style":{backgroundColor:'rgba(0,0,0,.5)'},"close-on-click-overlay":false},model:{value:(_vm.contentHtmlModal),callback:function ($$v) {_vm.contentHtmlModal=$$v},expression:"contentHtmlModal"}},[_c('div',{staticClass:"html",domProps:{"innerHTML":_vm._s(_vm.contentHtml)}}),_c('div',{staticClass:"close"},[_c('van-button',{staticStyle:{"width":"60%"},attrs:{"type":"primary","round":"","color":"#ec4a26"},on:{"click":()=>{_vm.contentHtmlModal = false}}},[_vm._v("确定")])],1)]):_vm._e(),(_vm.money.price)?_c('payment',{ref:"payment",attrs:{"data":_vm.json,"money":_vm.money,"showRetention":1}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }